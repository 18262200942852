<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP ESTIMATE LIST

type    : view

uses    : header-view
          header-list
          main-container
          table-status

route   : /estimates (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-estimate-list" v-if="estimates">
    <header-list title="Estimates" />
    <main-container id="estimateTableContainer">
      <div>
        <v-card>
          <v-card-title>
            <v-btn
              height="28"
              class="button-primary"
              @click="filterdialog = true">
              <span>Filter</span>
              <v-icon right small> mdi-filter </v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="QuickSearch"
              single-line
              hide-details />
          </v-card-title>
          <v-data-table
            id="estimateTable"
            :headers="headers"
            :items="filteredEstimates"
            class="elevation-1"
            :search="search"
            sort-by="number"
            sort-desc
            :loading="busy">
            <template v-slot:item="props">
              <tr
                @click="
                  $router.push({
                    name: 'EstimateView',
                    params: {uuid: props.item.uuid},
                  })
                ">
                <td>
                  {{
                    props.item.number
                      ? 'EST-' + props.item.number.toString().padStart(5, '0')
                      : 'Unavailable'
                  }}
                </td>
                <td>{{ props.item.client_name || 'Unassigned' }}</td>
                <td>{{ props.item.contact_name || 'Unassigned' }}</td>
                <td>
                  {{
                    displayDateFormat(props.item.due_date, dateFormat) ||
                    'Unassigned'
                  }}
                </td>
                <td>
                  {{
                    formatCurrency(props.item.total_price, currencySymbol) ||
                    'Unavailable'
                  }}
                </td>
                <td class="text-center">
                  <table-status :status="props.item.status" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat>
          <header-view title="Filter Estimates" dialog />
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="client_name"
                    label="Client Name Contains"
                    hint=""
                    required />
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="contact_name"
                    label="Contact Name Contains"
                    hint=""
                    required />
                </v-flex>
                <v-flex xs12>
                  <div id="estimate-status-filter">
                    <v-select
                      v-model="status"
                      :items="filterItems"
                      filled
                      label="Status" />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="button-primary" flat @click="applyFilter()">
              Apply
            </v-btn>
            <v-btn class="button-secondary" flat @click="clearFilter()">
              Clear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  // components
  import HeaderList from '@/components/header-list';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';

  // mixins
  import Localization from '@/mixins/localization';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Users from '@/services/Users.service.js';
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppEstimateList',
    components: {
      'header-view': HeaderView,
      'header-list': HeaderList,
      'main-container': MainContainer,
      'table-status': TableStatus,
    },
    mixins: [dateTimeHelpers, Localization],
    data() {
      return {
        filterdialog: false,
        client_name: '',
        contact_name: '',
        status: '',
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'In Progress',
            value: 'In Progress',
          },
          {
            text: 'Sent',
            value: 'Sent',
          },
          {
            text: 'Revising',
            value: 'Revising',
          },
          {
            text: 'Approved',
            value: 'Approved',
          },
          {
            text: 'Declined',
            value: 'Declined',
          },
        ],
        search: '',
        busy: false,
        headers: [
          {
            text: 'Estimate ID',
            align: 'left',
            sortable: true,
            value: 'number',
          },
          {
            text: 'Client',
            align: 'left',
            sortable: true,
            value: 'client_name',
          },
          {
            text: 'Contact Name',
            align: 'left',
            sortable: true,
            value: 'contact_name',
          },
          {
            text: 'Due Date',
            value: 'due_date',
          },
          {
            text: 'Total Price',
            value: 'total_price',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        estimates: null,
      };
    },
    computed: {
      currencySymbol() {
        const symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      // a computed getter
      filteredEstimates() {
        return this.estimates.filter(
          function (estimate) {
            let pass = true;
            if (
              this.client_name &&
              !estimate.client_name
                .toLowerCase()
                .includes(this.client_name.toLowerCase())
            ) {
              pass = false;
            }
            if (
              this.contact_name &&
              !estimate.contact_name
                .toLowerCase()
                .includes(this.contact_name.toLowerCase())
            ) {
              pass = false;
            }
            if (this.status && estimate.status != this.status) {
              pass = false;
            }
            return pass;
          }.bind(this)
        );
      },
    },
    async created() {
      this.busy = true;

      if (!this.$auth.userProfile.tenant_uuid) {
        console.log('could not get tenant uuid, aborting...');
        return;
      }

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get all estimates for tenant
      const res_estimates = await Jobs.getEstimates(
        {
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
        },
        accessToken
      );

      // validate
      if (res_estimates) {
        console.log(res_estimates);
        this.estimates = res_estimates;

        // get users
        const res_users = await Users.getUsersByTenant(
          this.$auth.userProfile.tenant_uuid,
          accessToken
        );

        // get clients
        const res_clients = await Clients.getClients(
          {
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
          },
          accessToken
        );

        if (res_users) {
          for (let i = 0; i < res_users.length; i++) {
            for (let j = 0; j < this.estimates.length; j++) {
              if (this.estimates[j].estimator_uuid == res_users[i].uuid) {
                this.estimates[j].estimator_name = res_users[i].name;
              }
            }
          }

          this.busy = false;
        } else {
          this.busy = false;
          console.log('could not load users');
        }

        // Next get all the clients, so we know which client the estimate is for
        if (res_clients) {
          this.clients = res_clients;

          for (let i = 0; i < res_clients.length; i++) {
            for (let j = 0; j < this.estimates.length; j++) {
              if (res_clients[i].uuid == this.estimates[j].client_uuid) {
                this.estimates[j].client_name = res_clients[i].client_name;
                this.estimates[j].contact_name = res_clients[i].contact_name;
              }
            }
          }

          this.busy = false;
        } else {
          this.busy = false;
          console.log('could not load clients');
        }
      } else {
        this.busy = false;
        console.log('could not load estimates');
      }
    },
    methods: {
      clearFilter: function () {
        this.client_name = '';
        this.contact_name = '';
        this.status = '';
        this.filterdialog = false;
      },
      applyFilter: function () {
        this.filterdialog = false;
        this.filteredEstimates();
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
